import React, { useState } from "react";
import { Link as ScrollLink, scroller } from "react-scroll";
import { IoMdMenu } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const [show, setShow] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const toggleShow = () => {
    setShow(!show);
  };

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleNavigation = (sectionId) => {
    if (location.pathname === "/") {
      // If already on the homepage, scroll directly
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 500,
        offset: -70, // Adjust offset for the navbar
      });
    } else {
      // Navigate to the homepage first
      navigate("/");
      // Scroll after navigation completes
      setTimeout(() => {
        scroller.scrollTo(sectionId, {
          smooth: true,
          duration: 500,
          offset: -70,
        });
      }, 300); // Delay to allow for route change
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div className="shadow-md">
      <div
        className={
          navbar
            ? `w-full fixed top-0 left-0 flex items-center justify-between px-4 md:px-10 lg:px-20 scroll z-50 shadow-md`
            : `w-full fixed flex items-center justify-between px-2 md:px-10 lg:px-20 py-2 normal`
        }
      >
        {navbar && <img src="Images/trizen.png" className="w-32 md:w-48" />}
        {navbar && (
          <div>
            <div className="md:flex gap-5 font-semibold hidden">
              <span
                onClick={() => handleNavigation("Home")}
                className="cursor-pointer"
              >
                Home
              </span>
              <span
                onClick={() => handleNavigation("About")}
                className="cursor-pointer"
              >
                About
              </span>
              <div
                className="relative cursor-pointer"
                onMouseEnter={() => setDropdown(true)}
                onMouseLeave={() => setDropdown(false)}
              >
                <span>Services</span>
                {dropdown && (
                  <div
                    className="absolute top-full left-1/2 transform -translate-x-1/2 bg-white shadow-lg rounded-md py-2 mt-1 w-58"
                    style={{
                      boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      onClick={() => navigate("/projects")}
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer transition-all duration-200 ease-in-out"
                    >
                      Projects
                    </div>
                  </div>
                )}
              </div>
              <span
                onClick={() => handleNavigation("Reviews")}
                className="cursor-pointer"
              >
                Reviews
              </span>
              <span
                onClick={() => handleNavigation("Team")}
                className="cursor-pointer"
              >
                Team
              </span>
              <span
                onClick={() => handleNavigation("Contact")}
                className="cursor-pointer"
              >
                Contact
              </span>
            </div>
          </div>
        )}

        {/* Mobile View */}
        <div className="flex md:hidden">
          <button
            onClick={() => toggleShow()}
            className="flex md:hidden float-right"
          >
            {show ? (
              <RxCross2 className="text-xl flex md:hidden" />
            ) : (
              <IoMdMenu className="text-xl flex md:hidden" />
            )}
          </button>
          {navbar && show && (
            <div>
              <div
                className="absolute top-12 right-6 flex flex-col md:hidden gap-5 bg-white px-14 py-10 text-xl rounded-md"
                style={{
                  boxShadow:
                    "0 0 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span
                  onClick={() => handleNavigation("Home")}
                  className="cursor-pointer"
                >
                  Home
                </span>
                <span
                  onClick={() => handleNavigation("About")}
                  className="cursor-pointer"
                >
                  About
                </span>
                <div
                  onClick={() => navigate("/projects")}
                  className="cursor-pointer"
                >
                  Projects
                </div>
                <span
                  onClick={() => handleNavigation("Reviews")}
                  className="cursor-pointer"
                >
                  Reviews
                </span>
                <span
                  onClick={() => handleNavigation("Team")}
                  className="cursor-pointer"
                >
                  Team
                </span>
                <span
                  onClick={() => handleNavigation("Contact")}
                  className="cursor-pointer"
                >
                  Contact
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
