import React from "react";
import "./App.css";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Feedback from "./Components/Feedback";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Services from "./Components/Services";
import Team from "./Components/Team";
import Footer from "./Components/Footer";
import { Routes, Route, Navigate } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import Projects from "./Components/Projects"; // Ensure correct import

function App() {
  return (
    <HashRouter>
    <div className="App">
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home />
              <About />
              <Services />
              <Feedback />
              <Team />
              <Contact />
            </>
          }
        />
        <Route path="/projects" element={<Projects />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </div>
    </HashRouter>
  );
}

export default App;


