import React from "react";
import { FileText, Lightbulb } from "lucide-react";

export function ProblemStatementCard({ problemStatement: ps }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md space-y-4">
      {/* ID and Technologies */}
      <div className="flex items-center justify-between">
        <span className="text-sm font-medium text-gray-900">{ps.id}</span>
      </div>

      {/* Title */}
      <div className="flex items-start gap-2">
        <FileText className="w-5 h-5 text-blue-500 flex-shrink-0 mt-1" />
        <h3 className="text-sm font-medium text-gray-900">{ps.title}</h3>
      </div>

      {/* Problem */}
      {/* <div className="flex items-start gap-2">
        <Lightbulb className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-1" />
        <p className="text-sm text-gray-600">{ps.problem}</p>
      </div> */}

      {/* Abstract */}
      <p className="text-sm text-gray-600">{ps.abstract}</p>

      <div className="flex flex-wrap gap-1">
          {ps.technologies.map((tech) => (
            <span
              key={tech}
              className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
            >
              {tech}
            </span>
          ))}
        </div>
    </div>
  );
}
