import React from "react";
import { FileText, Code } from "lucide-react";
import { ProblemStatementCard } from "./ProblemStatementCard";

export function ProblemStatementTable({ problemStatements }) {
  return (
    <>
      {/* Mobile and Tablet View (Cards) */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:hidden">
        {problemStatements.map((ps) => (
          <ProblemStatementCard key={ps.id} problemStatement={ps} />
        ))}
      </div>

      {/* Desktop View (Table) */}
      <div className="hidden lg:block overflow-x-auto bg-gray-50 rounded-lg shadow-lg border border-gray-200">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-blue-90">
            <tr>
              <th className="px-6 py-4 text-left text-sm font-semibold text-blue-700 uppercase tracking-wide">
                ID
              </th>
              <th className="px-6 py-4 text-left text-sm font-semibold text-blue-700 uppercase tracking-wide">
                Title
              </th>
              <th className="px-6 py-4 text-left text-sm font-semibold text-blue-700 uppercase tracking-wide">
                Abstract
              </th>
              <th className="px-6 py-4 text-left text-sm font-semibold text-blue-700 uppercase tracking-wide">
                Technologies
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {problemStatements.map((ps) => (
              <tr key={ps.id} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap text-base font-bold text-gray-800">
                  {ps.id}
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-start gap-3">
                    <FileText className="w-5 h-5 text-blue-500 flex-shrink-0 mt-1" />
                    <span className="text-base font-medium text-gray-900">{ps.title}</span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <p className="text-sm text-gray-700 max-w-xl leading-relaxed">
                    {ps.abstract}
                  </p>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-start gap-3">
                    <Code className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
                    <div className="flex flex-wrap gap-2">
                      {ps.technologies.map((tech) => (
                        <span
                          key={tech}
                          className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-blue-100 text-black-450"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
