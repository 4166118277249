import React from "react";

const domains = ["ALL", "AIML", "DeepLearning", "NLP", "IOT", "CyberSecurity", "Blockchain"];

const domainColors = {
  ALL: "bg-gray-800",
  CyberSecurity: "bg-gray-800",
  DeepLearning: "bg-gray-800",
  NLP: "bg-gray-800",
  Blockchain: "bg-gray-800",
  IOT: "bg-gray-800",
  AIML: "bg-gray-800",
};

export function DomainFilter({ selectedDomain, onDomainChange }) {
  return (
    <div className="overflow-x-auto pb-2 mb-6">
      <div className="flex flex-nowrap gap-2 min-w-min md:flex-wrap">
        {domains.map((domain) => (
          <button
            key={domain}
            onClick={() => onDomainChange(domain)}
            className={`px-4 py-2 rounded-full text-white transition-all whitespace-nowrap
              ${domainColors[domain]}
              ${
                selectedDomain === domain
                  ? "ring-0 ring-offset-2 ring-gray-400"
                  : "opacity-75 hover:opacity-100"
              }`}
          >
            {domain}
          </button>
        ))}
      </div>
    </div>
  );
}
