import React, { useState } from "react";
import { DomainFilter } from "./DomainFilter";
import { ProblemStatementTable } from "./ProblemStatementTable";
import { ProblemStatementCard } from "./ProblemStatementCard";

const sampleProblemStatements = [
  {
    "id": "CS-1",
    "title": "Cybersecurity Threat Prediction System Using Ensemble Learning",
    "problem": "Increasing cyberattacks and inadequate prediction mechanisms.",
    "abstract": "This system combines ensemble learning techniques like Random Forest and Gradient Boosting to predict cybersecurity threats. By analyzing network behavior, it identifies potential vulnerabilities and suggests mitigation strategies. Integration with cybersecurity platforms ensures seamless operation.",
    "technologies": ["Ensemble Learning", "Python", "Cybersecurity Tools", "Network Analysis"],
    "domain": "CS"
  },
  {
    "id": "DL-2",
    "title": "Autonomous Vehicle Lane Detection System Using Deep Learning",
    "problem": "Autonomous vehicles require robust lane detection for navigation.",
    "abstract": "This project uses convolutional neural networks (CNNs) to detect and track lanes in real-time from road images. The system integrates with LiDAR and GPS for enhanced accuracy. It supports autonomous navigation in diverse environments, including highways and urban streets.",
    "technologies": ["CNN", "Python", "OpenCV", "LiDAR", "GPS Integration"],
    "domain": "DL"
  },
  {
    "id": "DL-3",
    "title": "Emotion Recognition from Multi-Modal Data Using Deep Learning",
    "problem": "Limited accuracy in emotion detection systems using single data types.",
    "abstract": "This system combines data from facial expressions, speech, and physiological signals to enhance emotion detection accuracy. Deep learning models like CNN and RNN analyze the inputs and provide real-time emotion classification. The system can be applied to virtual assistants, education, and healthcare.",
    "technologies": ["Deep Learning", "CNN", "RNN", "Python", "NLP", "IoT Sensors"],
    "domain": "DL"
  },
  {
    "id": "DL-4",
    "title": "Advanced Deep Learning Model for Wildfire Detection",
    "problem": "Delayed wildfire detection leads to extensive environmental damage.",
    "abstract": "The project leverages drone imagery and deep learning models to detect wildfires at an early stage. By processing real-time data, the system sends immediate alerts to disaster management teams. GIS integration provides visual representations of affected areas for efficient resource allocation.",
    "technologies": ["Deep Learning", "Drones", "GIS", "Python"],
    "domain": "DL"
  },
  {
    "id": "NLP-5",
    "title": "AI-Based Fraud Detection in Online Learning Platforms",
    "problem": "Increase in fraudulent activities during online assessments.",
    "abstract": "This system uses AI to detect cheating during online exams by analyzing facial expressions, eye movements, and background audio. The system provides real-time alerts and detailed post-exam reports for administrators.",
    "technologies": ["Computer Vision", "NLP", "Python", "Proctoring APIs"],
    "domain": "NLP"
  },
  {
    "id": "NLP-6",
    "title": "Cyberbullying Detection on Social Media Using NLP",
    "problem": "Increasing instances of cyberbullying with inadequate automated detection systems.",
    "abstract": "This project uses NLP techniques to detect instances of cyberbullying in text and multimedia content on social media. The system categorizes the severity of abuse and provides alerts to administrators. Sentiment analysis and pattern recognition enhance detection accuracy.",
    "technologies": ["NLP", "Python", "Sentiment Analysis", "Machine Learning"],
    "domain": "NLP"
  },
  {
    "id": "NLP-7",
    "title": "Blockchain-Powered Fake News Detection",
    "problem": "Proliferation of fake news across digital platforms.",
    "abstract": "This project uses blockchain to verify news authenticity and provenance. A smart contract system validates the credibility of news sources and tags articles as credible or suspicious based on consensus. The system integrates with social media platforms for real-time detection and flagging of fake news.",
    "technologies": ["Blockchain", "Smart Contracts", "Python", "Natural Language Processing (NLP)"],
    "domain": "NLP"
  },
  {
    "id": "NLP-8",
    "title": "AI-Powered Stress Detection from Speech Patterns",
    "problem": "Lack of accessible and non-invasive methods for stress detection.",
    "abstract": "This system analyzes speech features such as pitch, tone, and rhythm to detect stress levels using machine learning models. It provides real-time feedback and integrates with wearable devices for continuous monitoring. The solution is designed for workplace stress management and mental health applications.",
    "technologies": ["Machine Learning", "Python", "NLP", "Audio Processing"],
    "domain": "NLP"
  },
  {
    "id": "Blockchain-9",
    "title": "Blockchain-Integrated Secure Cloud Storage for Medical Data",
    "problem": "Security challenges in storing and sharing medical data over cloud environments.",
    "abstract": "This project integrates blockchain technology with cloud computing to create a secure framework for storing and sharing medical records. It ensures data integrity, privacy, and seamless access for healthcare providers while preventing unauthorized access. A smart contract layer automates data sharing based on patient consent.",
    "technologies": ["Blockchain", "Smart Contracts", "Cloud Computing", "Python"],
    "domain": "Blockchain"
  },
  {
    "id": "Blockchain-10",
    "title": "Blockchain-Based Supply Chain Management System",
    "problem": "Lack of transparency in supply chain operations.",
    "abstract": "This project integrates blockchain to track products across the supply chain, ensuring transparency and reducing fraud. Smart contracts automate payments and compliance checks. The system supports diverse industries, including pharmaceuticals and agriculture.",
    "technologies": ["Blockchain", "Smart Contracts", "Python", "Supply Chain APIs"],
    "domain": "Blockchain"
  },
  // Add more data as needed
];

function Projects() {
  const [selectedDomain, setSelectedDomain] = useState("ALL");

  const filteredProblemStatements =
    selectedDomain === "ALL"
      ? sampleProblemStatements
      : sampleProblemStatements.filter((ps) =>
          ps.technologies.includes(selectedDomain)
        );

  return (
    <div className="p-6 space-y-6">
        <div className="flex items-center gap-4">
        <img src="Images/trizen.png" className="w-[130px] md:w-[170px]" alt="Trizen Logo" />
          <h1 className="text-xl md:text-3xl font-bold text-gray-800">
          Final Year Engineering Projects
          </h1>
        </div>
          <p className="text-sm sm:text-base text-gray-700 max-w-3xl">
            Explore our comprehensive collection of problem statements across multiple domains including
            Cybersecurity, Web Development, AI/ML, NLP, Deep Learning, and more. Each project is
            carefully designed to challenge and inspire innovation.
          </p>
      <DomainFilter
        selectedDomain={selectedDomain}
        onDomainChange={setSelectedDomain}
      />

      {/* Mobile and Tablet View (Cards) */}
      <div className="block lg:hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {filteredProblemStatements.map((ps) => (
            <ProblemStatementCard key={ps.id} problemStatement={ps} />
          ))}
        </div>
      </div>

      {/* Desktop View (Table) */}
      <div className="hidden lg:block">
        <ProblemStatementTable problemStatements={filteredProblemStatements} />
      </div>
    </div>
  );
}

export default Projects;
